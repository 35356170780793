import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API, ASSETS_API } from 'config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosInstance.get(url, { ...config });

    return res.data;
};

// ----------------------------------------------------------------------

export const ASSETS = (name: string) => `${ASSETS_API}/${name ?? 'no-image.png'}`;

// ----------------------------------------------------------------------

export const endpoints = {
    kyc: {
        checkUpdate: '/api/kyc/checkUpdate',
        verify: '/api/kyc/verify',
        create: '/api/kyc/create',
        update: '/api/kyc/update',
        re_send: '/api/kyc/re_send',
        get: '/api/kyc/get',

    },
    auth: {
        me: '/api/auth/dis_me',
        login: '/api/auth/dispensary_login',
        register: '/api/auth/dispensary_register'
    },
    item: {
        create: '/api/item/create',
        delete: '/api/item/delete',
        update: '/api/item/update',
        getById: '/api/item/getById',
        get: '/api/item/get',
        getMyItem: '/api/item/getMyItem',
        addOrRemoveMenu: '/api/item/addOrRemoveMenu',
        deleteFromMenu: '/api/item/deleteFromMenu',
    },
    profile: {
        update: '/api/dispensary/updateProfile',
        uploadPhotos: '/api/dispensary/uploadPhotos',
        setMainPhoto: '/api/dispensary/setMainPhoto',
        getPhotos: '/api/dispensary/getPhotos',
    },
    product: {
        list: '/api/product/list',
        details: '/api/product/details',
        search: '/api/product/search'
    },
    chat: '/api/chat',
    users: '/api/users',
    customers: '/api/customers',
    subcontractors: '/api/subcontractors',
    insurances: '/api/insurances',
    realEstates: '/api/realestates',
    roles: '/api/roles',
    invoices: '/api/invoices',
    estimates: '/api/estimates',
    projects: '/api/projects',
    jobs: '/api/jobs',
    todo: '/api/todo',
    note: '/api/note',
    contract: '/api/contract',
    notifications: '/api/notifications',
    fileManager: '/api/files',
    report: '/api/reports',
    communications: '/api/communications',
    accounting: '/api/accounting',
    paymentDetail: '/api/item-template',
    setting: '/api/setting',
    userSetting: '/api/user-setting',
    comment: '/api/comment',
    jobComment: '/api/job-comment',
    review: '/api/subcontractor-review',
    emailSnippet: '/api/email-snippet',
    photos: '/api/photos',
    tags: '/api/tags'
};
