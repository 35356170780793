import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'routes/paths';
import { useRouter } from 'routes/hook';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
    jwt: paths.auth.login
};

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function KycGuard({ children }: Props) {
    const router = useRouter();

    const { user } = useAuthContext();
    // const [checked, setChecked] = useState(false);

    // const check = () => {
    //     console.log("//////////////");

    //     if (!user?.kycVerified) {
    //         router.replace(`${paths.auth.kyc}`);
    //     } 

    //     // else {
    //     //     router.push(`${paths.dashboard.root}/items`);
    //     // }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // };

    useEffect(() => {
        if (!user?.kycVerified) {
            router.push(`${paths.auth.kyc}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.kycVerified]);

    // if (!checked) {
    //     return null;
    // }

    return <>{children}</>;
}
