import { palette as themePalette } from 'theme/palette';

// ----------------------------------------------------------------------

const palette = themePalette('light');

export const CALENDAR_COLOR_OPTIONS = [
    palette.primary.main,
    palette.secondary.main,
    palette.info.main,
    palette.info.darker,
    palette.success.main,
    palette.warning.main,
    palette.error.main,
    palette.error.darker
];

// ----------------------------------------------------------------------

export const STATUS_OPTIONS = [
    { id: 1, name: 'active' },
    { id: 2, name: 'closed' },
    { id: 3, name: 'lead' },
    { id: 4, name: 'collections' },
    { id: 5, name: 'appraisal' },
    { id: 6, name: 'lost' }
];

export const FOUNDUS_OPTIONS = ['google', 'referral', 'other'];

export const ESTIMATE_STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'declined', label: 'Declined' }
];

export const INVOICE_STATUS_OPTIONS = [
    { value: 'paid', label: 'Paid' },
    { value: 'pending', label: 'Pending' },
    { value: 'overdue', label: 'Overdue' },
    { value: 'draft', label: 'Draft' }
];

export const FILE_TYPE_OPTIONS = [
    'folder',
    'txt',
    'zip',
    'audio',
    'image',
    'video',
    'word',
    'excel',
    'powerpoint',
    'pdf',
    'photoshop',
    'illustrator'
];

export const TASK_SORT_OPTIONS = [
    { value: 'latest', label: 'Latest' },
    { value: 'oldest', label: 'Oldest' }
];

export const REPORT_SORT_OPTIONS = [
    { value: 'latest', label: 'Latest' },
    { value: 'oldest', label: 'Oldest' }
];

export const PROJECT_STATUS_OPTIONS = [
    { value: 'active', label: 'ACTIVE' },
    { value: 'closed', label: 'CLOSED' },
    { value: 'lead', label: 'LEAD' },
    { value: 'collections', label: 'COLLECTIONS' },
    { value: 'appraisal', label: 'APPRAISAL' },
    { value: 'lost', label: 'LOST' }
];

export const TASK_STATUS_OPTIONS = ['NEW', 'PENDING', 'INPROGRESS', 'COMPLETED', 'CANCELED'];

export const TASK_PRIORITY_OPTIONS = ['HIGH', 'MEDIUM', 'LOW'];

export const USER_STATUS_OPTIONS = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'inactive', label: 'Inactive' }
];

// ----------------------------------------------------------------------
// --------- business -----------
export const BUSINESS_TYPES = [
    {
        label: 'Dispensary',
        value: 'dispensary'
    },
    {
        label: 'Brand',
        value: 'brand'
    }
];
export const KYC_VERIFY_OPTION = ["pending", "approved", "rejected"];
export const BRAND_TYPES = [
    {
        label: 'Farm',
        value: 'farm'
    },
    {
        label: 'Processor',
        value: 'processor'
    }
];

export const STRAIN_SEARCH = [
    { label: 'All', value: 'Clear' },
    { label: 'Hybrid', value: 'Hybrid' },
    { label: 'Sativa', value: 'Sativa' },
    { label: 'Indica', value: 'Indica' },
    { label: 'Preroll', value: 'Preroll' },
    { label: 'Infused Preroll', value: 'Infused Preroll' },
    { label: 'Flower', value: 'Flower' },
    { label: 'Disposable', value: 'Disposable' },
    { label: 'Cartridge', value: 'Cartridge' },
    { label: 'Edible', value: 'Edible' },
    { label: 'Topical', value: 'Topical' }
];

export const STRAIN_DETAILS = [
    { label: 'Hybrid', value: 'Hybrid' },
    { label: 'Sativa', value: 'Sativa' },
    { label: 'Indica', value: 'Indica' },
    { label: 'Preroll', value: 'Preroll' },
    { label: 'Infused Preroll', value: 'Infused Preroll' },
    { label: 'Flower', value: 'Flower' },
    { label: 'Disposable', value: 'Disposable' },
    { label: 'Cartridge', value: 'Cartridge' },
    { label: 'Edible', value: 'Edible' },
    { label: 'Topical', value: 'Topical' }
];

export const STRAIN_GUIDE = [
    { label: 'Flower', value: 'Flower' },
    { label: 'Concentrate', value: 'Concentrate' },
    { label: 'Topical', value: 'Topical' },
    { label: 'Tincture', value: 'Tincture' },
    { label: 'Edible', value: 'Edible' },
    { label: 'Cartridge', value: 'Cartridge' },
    { label: 'Disposable', value: 'Disposable' },
    { label: 'Pre Roll', value: 'Pre Roll' },
    { label: 'Infused Pre Roll', value: 'Infused Pre Roll' }
];
export const STRAIN_UNITS = [
    { label: 'mg', value: 'mg' },
    { label: '%', value: '%' }
];
export * from './_imp';
export * from './_product';
export * from './_others';
export * from './assets';
