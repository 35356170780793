// routes
import { paths } from 'routes/paths';

// API
// ----------------------------------------------------------------------

export const ADMIN_EMAIL = 'admin@puffclub.com'

export const HOST_API =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HOST_API : process.env.REACT_APP_LOCAL_HOST_API;
export const ASSETS_API =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ASSETS_API : process.env.REACT_APP_LOCAL_ASSETS_API;
export const PDF_API =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PDF_API : process.env.REACT_APP_LOCAL_PDF_API;
export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API ?? '';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
