import { Children, lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard, KycGuard, RoleBasedGuard } from 'auth/guard';
// layouts
import DashboardLayout from 'layouts/dashboard';
// components
import { LoadingScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------
// OVERVIEW
const ProfilePage = lazy(() => import('pages/view/profile'));

export const profileRoutes = [
    {
        path: 'profile',
        element: (
            <AuthGuard>
                <KycGuard>
                    <DashboardLayout>
                        <Suspense fallback={<LoadingScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </KycGuard>
            </AuthGuard>
        ),
        children: [
            { index: true, element: <ProfilePage /> },
            { path: 'view', element: <ProfilePage /> },
        ]
    }
];
