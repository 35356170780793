import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'routes/paths';
import { useRouter } from 'routes/hook';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
    jwt: paths.auth.login
};

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
    const router = useRouter();

    const { authenticated, method } = useAuthContext();

    // const [checked, setChecked] = useState(false);

    // const check = useCallback(() => {
    //     if (!method || !router) {
    //         return
    //     }
        
    //     if (!authenticated) {
    //         const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();

    //         const loginPath = loginPaths[method];

    //         const href = `${loginPath}?${searchParams}`;

    //         router.replace(href);
    //     } else {
    //         setChecked(true);
    //     }
    // }, [authenticated, method, router]);

    // console.log(authenticated, "authenticated")
    // useEffect(() => {
    //     check();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [check]);

    useEffect(() => {
        if (!authenticated) {
            router.push(`${paths.auth.login}`);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    return <>{children}</>;
}
