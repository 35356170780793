// ----------------------------------------------------------------------

import { _id, _postTitles } from '_mock';

const ROOTS = {
    AUTH: '/auth',
    DASHBOARD: '/dashboard',
    ADMIN: '/admin'
};
const MOCK_ID = _id[1];
// ----------------------------------------------------------------------

export const paths = {
    // AUTH
    admin: {
        business: `${ROOTS.ADMIN}/business`,
    },
    // AUTH
    auth: {
        login: `${ROOTS.AUTH}/login`,
        opt: `${ROOTS.AUTH}/opt`,
        register: `${ROOTS.AUTH}/register`,
        kyc: `${ROOTS.AUTH}/kyc`,
    },
    // PRODUCT
    product: {
        root: `/product`,
        checkout: `/product/checkout`,
        details: (id: string) => `/product/${id}`,
        demo: {
            details: `/product/${MOCK_ID}`
        }
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        general: {
            app: `${ROOTS.DASHBOARD}/app`,
            items: `${ROOTS.DASHBOARD}/items`,
            my_items: `${ROOTS.DASHBOARD}/my_items`
        },
        product: {
            root: `${ROOTS.DASHBOARD}/product`,
            new: `${ROOTS.DASHBOARD}/product/new`,
            details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
            demo: {
                details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
                edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`
            }
        },
        user: {
            root: `${ROOTS.DASHBOARD}/user`,
            list: `${ROOTS.DASHBOARD}/user/list`,
            new: `${ROOTS.DASHBOARD}/user/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
            view: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/view`
        },
        customer: {
            root: `${ROOTS.DASHBOARD}/customer`,
            list: `${ROOTS.DASHBOARD}/customer/list`,
            new: `${ROOTS.DASHBOARD}/customer/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/customer/${id}/edit`,
            view: (id: string) => `${ROOTS.DASHBOARD}/customer/${id}/view`
        },
        subcontractor: {
            root: `${ROOTS.DASHBOARD}/subcontractor`,
            list: `${ROOTS.DASHBOARD}/subcontractor/list`,
            new: `${ROOTS.DASHBOARD}/subcontractor/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/subcontractor/${id}/edit`,
            view: (id: string) => `${ROOTS.DASHBOARD}/subcontractor/${id}/view`
        },
        insurance: {
            root: `${ROOTS.DASHBOARD}/insurance`,
            list: `${ROOTS.DASHBOARD}/insurance/list`,
            new: `${ROOTS.DASHBOARD}/insurance/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/insurance/${id}/edit`
        },
        realEstate: {
            root: `${ROOTS.DASHBOARD}/real-estate`,
            list: `${ROOTS.DASHBOARD}/real-estate/list`,
            new: `${ROOTS.DASHBOARD}/real-estate/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/real-estate/${id}/edit`
        },
        estimate: {
            root: `${ROOTS.DASHBOARD}/estimate`,
            list: `${ROOTS.DASHBOARD}/estimate/list`,
            new: `${ROOTS.DASHBOARD}/estimate/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/estimate/${id}/edit`,
            details: (id: string) => `${ROOTS.DASHBOARD}/estimate/${id}`
        },
        invoice: {
            root: `${ROOTS.DASHBOARD}/invoice`,
            list: `${ROOTS.DASHBOARD}/invoice/list`,
            new: `${ROOTS.DASHBOARD}/invoice/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
            details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`
        },
        accounting: {
            root: `${ROOTS.DASHBOARD}/accounting`,
            list: `${ROOTS.DASHBOARD}/accounting/list`,
            new: `${ROOTS.DASHBOARD}/accounting/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/accounting/${id}/edit`,
            details: (id: string) => `${ROOTS.DASHBOARD}/accounting/${id}`
        },
        project: {
            root: `${ROOTS.DASHBOARD}/project`,
            list: `${ROOTS.DASHBOARD}/project/list`,
            new: `${ROOTS.DASHBOARD}/project/new`,
            details: (id: string) => `${ROOTS.DASHBOARD}/project/${id}`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/project/${id}/edit`
        },
        job: {
            root: `${ROOTS.DASHBOARD}/job`,
            list: `${ROOTS.DASHBOARD}/job/list`,
            new: `${ROOTS.DASHBOARD}/job/new`,
            details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`
        },
        map: `${ROOTS.DASHBOARD}/map`,
        todo: `${ROOTS.DASHBOARD}/todo`,
        fileManager: `${ROOTS.DASHBOARD}/file-manager`,
        chat: `${ROOTS.DASHBOARD}/chat`,
        photo: `${ROOTS.DASHBOARD}/photo`,
        setting: `${ROOTS.DASHBOARD}/setting`,
        report: {
            list: `${ROOTS.DASHBOARD}/report/list`,
            new: `${ROOTS.DASHBOARD}/report/new`,
            edit: (id: string) => `${ROOTS.DASHBOARD}/report/${id}/edit`,
            details: (id: string) => `${ROOTS.DASHBOARD}/report/${id}`
        }
    },
    public: {
        project: (id: string) => `/project/${id}`,
        subcontractor: (id: string) => `/project/${id}`
    }
};
