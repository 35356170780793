import { Children, lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard, KycGuard, RoleBasedGuard } from 'auth/guard';
// layouts
import DashboardLayout from 'layouts/dashboard';
// components
import { LoadingScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------
// OVERVIEW
const OverviewBusinessPage = lazy(() => import('pages/admin/business'));
// ----------------------------------------------------------------------

export const AdminRoutes = [
    {
        path: 'admin',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { index: true, element: <Navigate to="business" /> },
            { path: 'business', element: <OverviewBusinessPage /> },
        ]
    }
];
