import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'config-global';
//
import { mainRoutes } from './main';
import { publicRoutes } from './public';
import { blankRoutes } from './blank';

import { authRoutes } from './auth';
import { profileRoutes } from './profile';
import { dashboardRoutes } from './dashboard';
import { AdminRoutes } from './admin';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <Navigate to={PATH_AFTER_LOGIN} replace />
        },
        // Admin routes
        ...AdminRoutes,
        // Auth routes
        ...authRoutes,
        // Dashboard routes
        ...dashboardRoutes,
        // profile routes
        ...profileRoutes,
        // Main routes
        ...mainRoutes,
        // Public routes
        ...publicRoutes,
        // Blank routes
        ...blankRoutes,

        // No match 404
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
