import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard, AuthGuard, KycGuard } from 'auth/guard';
// layouts
import AuthClassicLayout from 'layouts/auth/classic';
import AuthKycLayout from 'layouts/auth/kyc';
// components
import { SplashScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const LoginPage = lazy(() => import('pages/auth/login'));
const RegisterPage = lazy(() => import('pages/auth/register'));
const OPTPage = lazy(() => import('pages/auth/opt'));
// KYCVIEW
const KycViewPage = lazy(() => import('pages/view/kyc/index'));
// ----------------------------------------------------------------------

export const authRoutes = [
    {
        path: 'auth',
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: 'kyc',
                element: (
                    <AuthKycLayout>
                        <AuthGuard>
                            {/* <KycGuard> */}
                            <KycViewPage />
                            {/* </KycGuard> */}
                        </AuthGuard>
                    </AuthKycLayout>
                )
            },
            {
                path: 'login',
                element: (
                    <AuthClassicLayout>
                        <LoginPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'register',
                element: (
                    <AuthClassicLayout>
                        <RegisterPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'opt',
                element: (
                    <AuthClassicLayout>
                        <OPTPage />
                    </AuthClassicLayout>
                )
            }
        ]
    }
];
