export const TROCK_INC = {
    id: 'trock',
    name: 'Puff-Club',
    fullAddress: '3001 Long Prairie Rd. Ste. 200, Flower Mound, TX 75022',
    company: 'TRock Roofing & Contracting, LLC',
    email: 'help@trockcontracting.com',
    phoneNumber: '+1 (214) 548-4733',
    addressType: 'office'
};

export const WHITE_EDITOR_THEME = {
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': '',
    'common.backgroundColor': '#ffffff',
    'common.border': '0px',

    // header
    'header.backgroundColor': '#ffffff',
    'header.border': '0px',

    // icons default
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu primary color
    'submenu.backgroundColor': '#ffffff',
    'submenu.partition.color': '#dddddd',

    // submenu icons default
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu labels default
    'submenu.normalLabel.color': '#000000',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#009900',
    'submenu.activeLabel.fontWeight': 'bold',

    // checkbox style
    'checkbox.border': '1px solid #dddddd',
    'checkbox.backgroundColor': '#ffffff',

    // rango style
    'range.pointer.color': '#000000',
    'range.bar.color': '#666666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#000000',
    'range.disabledBar.color': '#eeeeee',
    'range.disabledSubbar.color': '#eeeeee',

    'range.value.color': '#000000',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '0px',
    'range.value.backgroundColor': '#f5f5f5',
    'range.title.color': '#000000',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #dddddd',
    'colorpicker.title.color': '#000000'
};
